<template>
	<div>
		<div class="s-login-title">欢迎来到森帮</div>
		<div class="s-login-strong">请填写您的手机号获取验证码登录</div>
		<div class="s-login-fromt">
			<div class="s-login-fromt-box">
				<div class="s-login-fromt-box-left">+86</div>
				<div class="s-login-fromt-border"></div>
				<div class="s-login-fromt-right">
					<input type="number" style="width: 100%;height: 100%;" placeholder="请输入手机号" v-model="mobile">
				</div>
			</div>
			<div class="s-login-fromt-box" style="justify-content: space-between;">
				<div class="s-login-fromt-box-code">
					<input type="number" placeholder="输入验证码" v-model="code">
				</div>
				<div class="s-login-fromt-box-code-text"
					:class="codeState?'s-login-fromt-box-code-text-orange':'s-login-fromt-box-code-text-gary'"
					@click="onObtainCode">{{codeText}}</div>
			</div>
		</div>
		<div class="s-login-pass">
			<div class="s-login-pass-text" @click="onPassLogin">密码登录</div>
		</div>
		<div class="s-login-button" @click="onLogin">登录</div>

	</div>
</template>

<script>
import api from "@/api/api"
	export default {
		data() {
			return {
				mobile: '', //用户手机号
				code: '', //用户收到的短信验证码
				openid: 1, //微信授权登录后的
				codeText: '获取验证码',
				codeState: true,
				time: 60,
			}
		},
		mounted() {



		},
		methods: {
			//获取验证码
			onObtainCode() {
				var _this = this;
				if (_this.codeState) {
					if (_this.mobile == '') {
						_this.$toast('请输入手机号');
					} else if (!(/^1[3456789]\d{9}$/.test(_this.mobile))) {
						_this.$toast('请输入正确的手机号');
					} else {
						_this.$toast.loading({
							message: '加载中...',
							forbidClick: true,
							duration: 0
						});

            if(_this.openid){
              _this.getcode()
            }else {
              localStorage.setItem("mobile", _this.mobile)
              _this.getAuthInfo()
            }




					}

				}

			},
      getcode(){

        let _this = this
        _this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0
        });
        var mobile = _this.mobile;
        var myData = new URLSearchParams();
        myData.append("mobile", mobile);
        myData.append("code_type", "1");
        myData.append("openid", _this.openid);
        _this.$http({
          method: 'post',
          url: 'users/api.user/send_sms_code',
          data: myData
        }).then((res) => {
          console.log(res)

          if (res.data.code == 0) {
            _this.$toast('信息发送成功');
            var time = 60;
            var codeTime = setInterval(function() {
              time--;
              if (time <= 0) {
                _this.codeState = true;
                clearTimeout(codeTime);
                _this.codeText = "重新获取"
              } else {
                _this.codeText = time + '后重新获取'
                _this.time = time;
                _this.codeState = false;

              }
            }, 1000)
          } else {
            _this.$toast(res.data.msg);
          }
        }).catch(() => {
          _this.$toast('网络请求失败');
        });
      },
      getStr(url, str) {
        var string = url.split('?')[1]
        if (!string) {
          return null
        }
        var arr1 = string.split('&');
        for (var i in arr1) {
          var arr2 = arr1[i].split("=");
          if (str == arr2[0]) {
            return arr2[1]
          }
        }
        return null
      },
      getAuthInfo(){
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          let url = window.location.href;
          var redirect_uri = encodeURIComponent(url)
          console.log(url)
          console.log(redirect_uri)
          var code = this.getStr(url, "code");
          if (code) {
            this.getOpenid(code);
          } else {

            api.index.getAuthInfo(redirect_uri, 'snsapi_userinfo').then(res => {
              console.log(res)
              if (res.data.code == 0) {
                location.href = res.data.authUrl;
              } else {
                this.$toast(res.data.msg)
              }
            })
          }
        }

      },
			//登录
			onLogin() {
				var _this = this;
				if (_this.mobile == '') {
					_this.$toast('请输入手机号');
				} else if (!(/^1[3456789]\d{9}$/.test(_this.mobile))) {
					_this.$toast('请输入正确的手机号');
				} else if (_this.code == '') {
					_this.$toast('请输入验证码');
				} else {
					_this.$toast.loading({
						message: '加载中...',
						forbidClick: true,
						duration: 0
					});
					var myData = new URLSearchParams();
					myData.append("mobile", _this.mobile);
					myData.append("code", _this.code);
					myData.append("openid", _this.openid);
					this.$http({
						method: 'post',
						url: 'users/api.user/login_by_sms_code',
						data: myData
					}).then((res) => {
						if (res.data.code == 0) {
							_this.$toast.clear();
							var data = res.data.user_info
							var nickName = '';
							if(data.jj_nick_name){
								nickName = data.jj_nick_name
							}else if(data.user_mobile){
								nickName = data.user_mobile
							}else{
								nickName = data.user_name
							}
							localStorage.setItem("nickName",nickName)
							localStorage.setItem("login_token",res.data.login_token)
							_this.$router.push({
								path:"/my"
							})
							
						} else {
							_this.$toast(res.data.msg);
						}

					}).catch(() => {
						_this.$toast('网络请求失败');
					});

				}
			},
			//密码登录
			onPassLogin() {
				this.$router.push({
					path: "/pass",
				})
			},
      WXgetUserInfo(openid) {
        api.index.WXgetUserInfo(openid).then(res => {
          if (res.data.code == 0) {
            var userinfo = res.data.userinfo
            this.$store.commit("keepWxUserInfo", userinfo)
          }
        })
      },
      getOpenid(code) {
        api.index.getOpenid(code).then(res => {
          if (res.data.code == 0) {
            var openid = res.data.openid
            this.$store.commit("keepOpenid", openid)
            this.WXgetUserInfo(openid)
            localStorage.setItem("openid", openid)
            this.openid = openid
            var appHttp = window.location.href;
            var appHttpArr = appHttp.split('/');
            console.log(1)
            console.log(appHttpArr)
            // location.href = appHttpArr[0] + '//' + appHttpArr[2] + '/#/' + appHttpArr[4]
            this.getcode()
            localStorage.removeItem("mobile")
          } else {
            this.$toast(res.data.msg)
          }
        })
      },
		},
		created(){
      let _this = this
      _this.mobile = localStorage.getItem("mobile");
      _this.openid = this.$store.state.openid
      console.log(111)
      if(_this.mobile){

        if(!_this.openid){
          var ua = window.navigator.userAgent.toLowerCase();
          if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            let url = window.location.href;
            var redirect_uri = encodeURIComponent(url)
            console.log(url)
            console.log(redirect_uri)
            var code = this.getStr(url, "code");
            if (code) {
              _this.getOpenid(code);
            }
          }
          console.log(222)
        }else {
          _this.getcode()
          console.log(1118)
          localStorage.removeItem("mobile")
        }

      }

		}
	}
</script>

<style scoped>
	.s-login-button {
		width: 6.14rem;
		height: 1.16rem;
		background-color: #f15223;
		border-radius: 99999999px;
		line-height: 1.16rem;
		color: #ffffff;
		text-align: center;
		margin: 0 auto;
		margin-top: 1.2rem;
	}

	.s-login-pass-text {
		padding: 0.1rem;
	}

	.s-login-pass {
		display: flex;
		justify-content: flex-end;
		padding: 0 0.64rem;
		font-size: 0.26rem;
		color: #5b5958;
	}

	.s-login-fromt-box-code-text-gary {
		color: #ccc;
	}

	.s-login-fromt-box-code-text-orange {
		color: #FF865C;
	}

	.s-login-fromt-box-code-text {
		height: 1.28rem;
		font-size: 0.28rem;
		padding-right: 0.3rem;
		line-height: 1.28rem;
	}

	.s-login-fromt-box-code>input::placeholder {
		color: #d7b6b9;
	}

	.s-login-fromt-box-code>input {
		display: inline-block;
		border: none;
		background-color: transparent;
		outline: none;
		width: 3rem;
		height: 1.28rem;
		font-size: 0.28rem;
	}

	.s-login-fromt-box-code {
		padding-left: 0.7rem;
	}

	.s-login-fromt-right>input::placeholder {
		color: #d7b6b9;
	}

	.s-login-fromt-right>input {
		border: none;
		background-color: transparent;
		outline: none;
	}

	.s-login-fromt-right {
		width: 4.1rem;
		height: 1.28rem;
		font-size: 0.28rem;
		margin-left: 0.6rem;
	}

	.s-login-fromt-border {
		width: 0.02rem;
		height: 0.32rem;
		background-color: #d7b6b9;
	}

	.s-login-fromt-box-left {
		width: 1.6rem;
		height: 1.28rem;
		line-height: 1.28rem;
		font-size: 0.26rem;
		color: #ff865c;
		text-align: center;
		font-weight: bold;
	}

	.s-login-fromt-box {
		width: 6.6rem;
		height: 1.28rem;
		border-radius: 0.15rem;
		background-color: rgba(254, 241, 242, 1);
		display: flex;
		margin-bottom: 0.4rem;
		align-items: center;
	}

	.s-login-fromt {
		padding: 1.4rem 0.45rem 0;
	}

	.s-login-strong {
		font-size: 0.26rem;
		margin-left: 1rem;
		margin-top: 0.4rem;
	}

	.s-login-title {
		font-size: 0.62rem;
		padding-top: 2rem;
		font-weight: bold;
		margin-left: 1rem;
		color: #5b5958;
	}
</style>
