import axios from "axios"
import store from "@/store/store"
// const baseURL = process.env.NODE_ENV==="production"?"https://gztest.hengyitianjin.com":"https://backtest.hengyitianjin.com"
// const baseURL ="https://backtest.kuangshichuangtian.com/" /*测试*/
const baseURL ="https://senbangback.kuangshichuangtian.com/" /*线上*/
var instance = axios.create({
    baseURL:baseURL,
    timeout:15000,
})
instance.interceptors.request.use(function (config) {
    if(store.state.openid){
        // config.data = store.state.openid; //将接口返回的token信息配置到接口请求中
    }
    return config;
},function (error) {
    return Promise.reject(error);
});
instance.interceptors.response.use(response=>{
    if(response.data.code == -2){
        location.href="#/login"
        localStorage.removeItem("login_token")
        localStorage.removeItem("openid")
        localStorage.removeItem("nickName")
    }else if(response.data.code == -100){
        location.href="#/login"
        localStorage.removeItem("login_token")
        localStorage.removeItem("openid")
        localStorage.removeItem("nickName")
    } else{
        return response
    }

},function (error) {
    return Promise.reject(error);
});

export default instance