import http from "../config/http"
export default {
    getAuthInfo(url,type){
        return http.post(http.defaults.baseURL + "/users/api.wechat/getAuthInfo?url=" + url + "&type=" + type)
    },
    getOpenid(code){
        return http.post(http.defaults.baseURL + "/users/api.wechat/getOpenidInfo?code=" + code)
    },
    WXgetUserInfo(openid){
        return http.post(http.defaults.baseURL + "/users/api.wechat/getUserInfoByOpenid?openid=" + openid)
    },
    getArticleType(openid){
        return http.post(http.defaults.baseURL + "/articles/api.articles/getArticleCates?openid=" + openid)
    },
    getArticleList(openid,page,orderBy,cate_id,keyword){
        return http.post(http.defaults.baseURL + "/articles/api.articles/index?openid=" + openid + "&page=" + page + "&orderBy=" + orderBy + "&cate_id=" + cate_id + "&keyword=" + keyword)
    }
}