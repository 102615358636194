import http from "../config/http"
export default {
    getdata(openid,article_id,from_uid,trans_uid,is_show){
        return http.post(http.defaults.baseURL + "/articles/api.articles/getArticleDetail?openid=" + openid + "&article_id=" + article_id + "&from_uid=" + from_uid+"&trans_uid=" + trans_uid+'&is_show='+is_show)
    },
    modifyIntroduce(openid,introduce){
        return http.post(http.defaults.baseURL + "/users/api.user/edit_user_introduct?openid=" + openid + "&introduce=" + introduce)
    },
    geturl(url){
        return http.post(http.defaults.baseURL + "/users/api.wechat/jsSdkSign?url=" + url)
    },
    share(openid,article_id,from_uid,cate_id){
        return http.post(http.defaults.baseURL + "/articles/api.articles/articleForward?openid=" + openid + "&article_id=" + article_id + "&from_uid=" + from_uid + "&cate_id=" + cate_id )
    },
}