import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'
Vue.use(Router)
import login from '@/view/login/login'
const routes = [
    {
        path:'/login',
        name:'login',
        meta:{
            title:'手机号登录'
        },
        component:login
    },
    {
        path:'/applypc',
        name:'applypc',
        meta:{
            title:'pc申请服务费'
        },
        component:()=>import('@/view/login/applypc')
    },
    {
        path:'/',
        name:'首页',
        meta:{
            title:'首页'
        },
        component:()=>import('@/view/index/index')
    },
    {
        path:'/customer',
        name:'customer',
        meta:{
            title:'客户'
        },
        component:()=>import('@/view/customer/customer')
    },
    {
        path:'/my',
        name:'我的',
        meta:{
            title:'我的'
        },
        component:()=>import('@/view/my/my.vue')
    },
    {
        path:'/details',
        name:'details',
        meta:{
            title:'详情'
        },
        component:()=>import('@/view/details/details')
    },
    {
        path:'/apply',
        name:'apply',
        meta:{
            title:'申请服务费'
        },
        component:()=>import('@/view/my/apply')
    },
    {
        path:'/applyRecord',
        name:'applyRecord',
        meta:{
            title:'申请记录'
        },
        component:()=>import('@/view/my/applyRecord')
    },
    {
        path:'/modifyApply',
        name:'modifyApply',
        meta:{
            title:'申请记录'
        },
        component:()=>import('@/view/my/modifyApply')
    },
    {
        path:'/seeApply',
        name:'seeApply',
        meta:{
            title:'申请详情'
        },
        component:()=>import('@/view/my/seeApply')
    },
    {
        path:'/mail',
        name:'mail',
        meta:{
            title:'邮寄发票'
        },
        component:()=>import('@/view/my/mail')
    },
    {
        path: '/pass',
        name: 'pass',
        meta: {
            title: '密码登录'
        },
        component: (resolve) => require(['./view/login/pass.vue'], resolve)
    }, {
        path: '/realname/service/service',
        name: 'realname_service_service',
        meta: {
            title: '申请服务费'
        },
        component: (resolve) => require(['./view/realname/service/service.vue'], resolve)
    },{
        path: '/realname/cooperation/mode',
        name: 'realname_cooperation_mode',
        meta: {
            title: '选择合作方式'
        },
        component: (resolve) => require(['./view/realname/cooperation/mode.vue'], resolve)
    },{
        path: '/realname/cooperation/explain',
        name: 'realname_cooperation_explain',
        meta: {
            title: '合作说明'
        },
        component: (resolve) => require(['./view/realname/cooperation/explain.vue'], resolve)
    },{
        path: '/realname/cooperation/authentication',
        name: 'realname_cooperation_authentication',
        meta: {
            title: '认证'
        },
        component: (resolve) => require(['./view/realname/cooperation/authentication.vue'], resolve)
    },{
        path: '/realname/cooperation/list',
        name: 'realname_cooperation_list',
        meta: {
            title: '查看合作方式'
        },
        component: (resolve) => require(['./view/realname/cooperation/list.vue'], resolve)
    },{
        path: '/realname/card/account',
        name: 'realname_card_account',
        meta: {
            title: '收款账户'
        },
        component: (resolve) => require(['./view/realname/card/account.vue'], resolve)
    },{
        path: '/realname/card/add',
        name: 'realname_card_add',
        meta: {
            title: '添加/修改银行卡'
        },
        component: (resolve) => require(['./view/realname/card/add.vue'], resolve)
    },{
        path: '/realname/invoice/explain',
        name: 'realname_invoice_explain',
        meta: {
            title: '开票说明'
        },
        component: (resolve) => require(['./view/realname/invoice/explain.vue'], resolve)
    },
]
const router = new Router({
    routes
})
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title
    }
    if(from.path=="/"){
        store.commit('keepScrollTop', document.body.scrollTop || document.documentElement.scrollTop );
    }
    if(to.path=="/"){
        setTimeout(()=>{
                document.documentElement.scrollTop = store.state.scrollTop
                document.body.scrollTop = store.state.scrollTop;
        },50)
      next();
    }else{
        let userinfo=localStorage.getItem("login_token")
        if(userinfo){
            next();
        }else{
            if(to.path == "/details" || to.path == "/customer" || to.path == "/my" || to.path == "/login" || to.path == "/pass" || to.path == "/applypc"){
                next();
            }else{
                next('/login')
            }
        }
    }
  })
export default router