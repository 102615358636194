import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'
import store from './store/store'
import vant from 'vant'
import 'vant/lib/index.css'
import 'u-reset.css'
import copy from 'vue-clipboard2'
import VueClipboard from 'vue-clipboard2'
Axios.defaults.baseURL = 'https://senbangback.kuangshichuangtian.com/';//线上数据地址公共部分
// Axios.defaults.baseURL = 'https://backtest.kuangshichuangtian.com' /* 测试 */
Axios.defaults.headers = {
  'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
}
Vue.use(VueClipboard)

Vue.use(copy)
Vue.use(vant)
Vue.config.productionTip = false
Vue.prototype.$http = Axios

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
