<template>
  <div id="app">
    <keep-alive include="index">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import api from "@/api/api"

export default {
  name: 'App',
  data() {
    return {
      code: "",
    }
  },
  methods: {
    // 获取code
    getAuthInfo(url, type) {
      api.index.getAuthInfo(url, type).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          location.href = res.data.authUrl;
        } else {
          this.$toast(res.data.msg)
        }
      })
    },
    // 截取code
    getStr(url, str) {
      var string = url.split('?')[1]
      if (!string) {
        return null
      }
      var arr1 = string.split('&');
      for (var i in arr1) {
        var arr2 = arr1[i].split("=");
        if (str == arr2[0]) {
          return arr2[1]
        }
      }
      return null
    },
    getOpenid(code) {
      api.index.getOpenid(code).then(res => {
        if (res.data.code == 0) {
          var openid = res.data.openid
          this.$store.commit("keepOpenid", openid)
          this.WXgetUserInfo(openid)
          localStorage.setItem("openid", openid)
          var appHttp = window.location.href;
          var appHttpArr = appHttp.split('/');
          console.log(1)
          console.log(appHttpArr)
          location.href = appHttpArr[0] + '//' + appHttpArr[2] + '/#/' + appHttpArr[4]
        } else {
          this.$toast(res.data.msg)
        }
      })
    },
    WXgetUserInfo(openid) {
      api.index.WXgetUserInfo(openid).then(res => {
        if (res.data.code == 0) {
          var userinfo = res.data.userinfo
          this.$store.commit("keepWxUserInfo", userinfo)
        }
      })
    }
  },
  created() {
    // var openid = this.$store.state.openid;
    // console.log(openid)
    // var ua = window.navigator.userAgent.toLowerCase();
    // if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    //   let url = window.location.href;
    //   var redirect_uri = encodeURIComponent(url)
    //   console.log(url)
    //   console.log(redirect_uri)
    //   var code = this.getStr(url, "code");
    //   if (openid) {
    //     this.WXgetUserInfo(openid)
    //   } else {
    //     if (code) {
    //       this.getOpenid(code);
    //     } else {
    //       this.getAuthInfo(redirect_uri, "snsapi_userinfo")
    //
    //     }
    //   }
    // } else {
    //   return false;
    // }

  }
}
</script>

<style>
html,
body {
  height: 100%;
}

body {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  overflow: auto;
}

#app {
  width: 100%;
  height: 100%;
  color: #2c3e50;
}

.van-tab {
  line-height: 105% !important;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.mr {
  margin-right: .24rem;
}

::-webkit-scrollbar {
  display: none;
}

input::-webkit-input-placeholder {
  color: #FFDFDF;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #FFDFDF;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #FFDFDF;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #FFDFDF;
}

input, textarea {
  -webkit-appearance: none;
}
</style>
