import Vue from 'vue'
import Vuex from 'vuex'
import VuxPersist from 'vuex-persist'
Vue.use(Vuex)
const vuexLocal = new VuxPersist({
    storage:window.localStorage
})
const store = new Vuex.Store({
    plugins:[vuexLocal.plugin],
    state:{
        openid:"",
        WxUserInfo:null,
        scrollTop:0,
        scrollLeft:0,
    },
    mutations:{
        keepOpenid(state,openid){
            state.openid=openid
        },
        keepWxUserInfo(state,user){
            state.WxUserInfo=user;
        },
        keepScrollTop(state,scroll){
            state.scrollTop=scroll;
        },
        keepScrollLeft(state,scroll){
            state.scrollLeft=scroll;
        }
    }
})
export default store