import http from "../config/http"
export default {
    applyPrice(openid,login_token,apply_money){
        return http.post(http.defaults.baseURL + "/users/api.finance/checkUserBanance?openid=" + openid + "&login_token=" + login_token + "&apply_money=" + apply_money + "&is_ajax=1")
    },
    apply(openid,login_token,apply_money,apply_data,invoice_file,invoice_type,invoice_img,bank_id,is_back,user_id){
        return http.post(http.defaults.baseURL + "/users/api.finance/applyServiceFee",{
            openid,login_token,apply_money,apply_data,invoice_file,invoice_type,invoice_img,bank_id,is_back,user_id
        })
    },
    applyNew(openid,login_token,apply_money,bank_id,is_back,user_id){
        return http.post(http.defaults.baseURL + "/users/api.finance/applyServiceFeeNew",{
            openid,login_token,apply_money,bank_id,is_back,user_id
        })
    },
    modifyApply(openid,login_token,apply_money,apply_data,invoice_file,invoice_type,invoice_img,bank_id,apply_id,del_ids){
        return http.post(http.defaults.baseURL + "/users/api.finance/updateApplyData",{
            openid,login_token,apply_money,apply_data,invoice_file,invoice_type,invoice_img,bank_id,apply_id,del_ids
        })
    },
    modifyApplyNew(openid,login_token,apply_money,bank_id,apply_id){
        return http.post(http.defaults.baseURL + "/users/api.finance/updateApplyDataNew",{
            openid,login_token,apply_money,bank_id,apply_id
        })
    },
    getdata(openid,login_token,isback,user_id,page){
        return http.post(http.defaults.baseURL + "/users/api.finance/apply_service_init",
            {
                openid:openid,
                login_token:login_token,
                is_back:isback,
                user_id:user_id,
                page:page
            }
        )
    },
    applyRecord(openid,login_token,page){
        return http.post(http.defaults.baseURL + "/users/api.finance/getServiceApplyInfo",{
            openid:openid,
            login_token:login_token,
            page:page,
        })
    },
    cancleApply(openid,login_token,apply_id,num){
        return http.post(http.defaults.baseURL + "/users/api.finance/cancle_apply_service",{
            openid,
            login_token,
            apply_id,num
        }) 
    },
    getApplyInfo(openid,login_token,apply_id){
        return http.post(http.defaults.baseURL + "/users/api.finance/getApplyData",{
            openid,
            login_token,
            apply_id
        }) 
    },
    getApplyInfoNew(openid,login_token,apply_id){
        return http.post(http.defaults.baseURL + "/users/api.finance/getApplyDataNew",{
            openid,
            login_token,
            apply_id
        })
    },

    // 个人获取费率
    getCost(openid,login_token,apply_money,isback,user_id){
        return http.post(http.defaults.baseURL + "/users/api.finance/get_person_apply_fee",{
            openid,
            login_token,
            apply_money,
            is_back:isback,
            user_id:user_id
        }) 
    },
    // 邮寄发票
    applyMail(openid,login_token,apply_id,express_company,express_no,invoice_type,invoice_img,invoice_file){
        return http.post(http.defaults.baseURL + "/users/api.finance/addApplyDetailData",{
            openid,
            login_token,
            apply_id,
            express_company,express_no,
            invoice_type,invoice_img,invoice_file

        }) 
    },
    getMail(openid,login_token,apply_id){
        return http.post(http.defaults.baseURL + "/users/api.finance/addApplyInvoiceData",{
            openid,
            login_token,
            apply_id
        }) 
    },
}